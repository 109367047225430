export const environment = {
  production: true,
  api:{
    // url: 'http://18.217.100.158:8080'
    url: 'https://ajaxapi.newtribe.nl',
    appVersion: '8'
  },
  firebase: {
    apiKey: 'AIzaSyD0li4Ouebwe5Eoo6X6LY-3Y5tvmluS4rM',
    authDomain: 'ajaxprod-eac95.firebaseapp.com',
    databaseURL: 'https://ajaxprod-eac95.firebaseio.com',
    projectId: 'ajaxprod-eac95',
    storageBucket: '',
    messagingSenderId: '809951371487',
    appId: '1:809951371487:web:c528b534d7946cf30b496a'
  }
};