import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, concatMap, map, withLatestFrom, take } from 'rxjs/operators';
import { AppState } from 'src/app/reducers'
import * as AccountActions from '../../auth/account.actions'
import { WebSocketClient } from '../../socket/socket-client-service'
import { TournamentHttpService } from '../services/tournament-http.service'
import * as TournamentDataActions from './tournament-data.actions'
import * as TournamentDataSelectors from './tournament-data.selectors'
import * as ChatboxActions from '../../actions/chatbox.actions'


@Injectable()
export class TournamentDataEffects {

  triggerLoadTournamentData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TournamentDataActions.fetchTournaments),
      concatMap(() =>
        this.tournamentHttp.fetchTournaments(undefined, undefined, undefined).pipe(
          map(data => TournamentDataActions.fetchTournamentsSuccess({ data })),
          catchError(error => of(TournamentDataActions.fetchTournamentsFailure({ error })))),
      ),
    )
  })


  loadTournamentsAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.setAuthorities),
      take(1),
      concatMap(
        () => of(TournamentDataActions.fetchTournaments()),
      ),
    )
  })


  subscribeToTournaments = createEffect(() => {
    return this.actions$.pipe(
      ofType(TournamentDataActions.fetchTournamentsSuccess),
      concatMap(({ data }) => {

        console.log('^^, 1')
        data.filter(tournament => tournament.status === 0).map(({ id }) => {
          console.log('^^ 2, id', id )
          this.socketClient.reportsSubscribe(id)
        })

        return of(TournamentDataActions.multipleReportsSubscribed())
      },
      ),
    )
  })



  loadModerators$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TournamentDataActions.fetchModerators,
        TournamentDataActions.setModeratorsSearch,
        TournamentDataActions.setModeratorsPaginator,
      ),
      withLatestFrom(
        this.store.select(TournamentDataSelectors.selectModeratorsPaginator),
        this.store.select(TournamentDataSelectors.selectModeratorsSearch),
      ),

      concatMap(([action, { page, size }, search]) =>
        this.tournamentHttp.fetchModerators(page, size, search).pipe(
          map( response => {

            const { body } = response
            const length = + response.headers.get('X-Total-Count')

            return TournamentDataActions.fetchModeratorsSuccess({ data: body['data'], length })

          }),
          catchError(error => of(TournamentDataActions.fetchModeratorsFailure({ error })))),
      ),
    )
  })

  initiateChatAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TournamentDataActions.initiateChat),
      concatMap(( { userId } ) =>
        this.tournamentHttp.initiateChat(userId).pipe(
          map(data => TournamentDataActions.initiateChatSuccess({ userId })),
          catchError(error => of(TournamentDataActions.initiateChatFailure({ error })))),
      ),
    )
  })

  // initiateChatSuccess$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(TournamentDataActions.initiateChatSuccess),
  //     concatMap( ({ userId })=> of(ChatboxActions.) 
  //     // ? of()
  //     // : of(ChatboxActions.fetchModeratorChatList()),
  //     ),
  //   )
  // })

  constructor(
    private actions$: Actions,
    private tournamentHttp: TournamentHttpService,
    private socketClient: WebSocketClient,
    private store: Store<AppState>,
    // private moderatorsHttp: Moderatorservi,
  ) {}

}
