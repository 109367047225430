import { Action, createReducer, on } from '@ngrx/store'
import * as _ from 'lodash'
import * as ChatboxActions from '../actions/chatbox.actions'
import { markReadModeratorMessageSuccess } from '../actions/chatbox.actions'
import { ChatboxTabs } from '../chatbox/models/ChatboxTabs.enum'
import { onMessage } from '../socket/socket-manager.actions'
import { UnreadChatType } from '../unread/models/UnreadChatType.enum'
import * as UnreadFeatureActions from '../unread/store/unread-feature.actions'
import { compare, ObjectDeepDiff, deepDiffInitialState } from './chatbox.utils';

export const chatboxFeatureKey = 'chatbox'

export interface ChatboxState {
  open: boolean
  activeTab: ChatboxTabs
  userId: string
  tournamentId: string
  hasUnread: boolean

  // Shared moderators room in particular tournament
  tmMessages: {
    [key: string]: any,
  }

  moderatorChatList: {}
  moderatorMessagesChains: {},

  hasModeratorUnread: boolean

  moderatorsList: {}
  moderatorActiveChat: string | undefined


  tournamentsDirect: {
    [key: string]: {
      chatData: any,
      diffWithPrevious: any,
      activeUsers: [],
      data?: any,
      activeId: string | undefined,
      totalUnread: number | undefined,
    },
  }

  directMsgData: {
    [key: string]: any,
  }

  unreadData: any
  moderatorDirectUnreadData: {
    [key: string]: any,
  }

  supportNormalizedData: {
    [key: string]: any,
  }

  lastReportsState: RawReportsChain
  diffWithPrevious: ObjectDeepDiff
}


export const initialState: ChatboxState = {
  open: false,
  activeTab: ChatboxTabs.TM,
  userId: undefined,
  tournamentId: undefined,
  directMsgData: undefined,
  hasUnread: false,
  tmMessages: {},

  moderatorChatList: {},
  moderatorMessagesChains: {},
  hasModeratorUnread: false,

  moderatorsList: {},
  moderatorActiveChat: undefined,

  tournamentsDirect: {},

  lastReportsState: {},
  diffWithPrevious: {
    different: [],
    missing_from_first: [],
    missing_from_second: [],
  },

  unreadData: {},
  moderatorDirectUnreadData: {},
  supportNormalizedData: {},
}



const chatboxReducer = createReducer(
  initialState,

  on(ChatboxActions.onModeratorDirectTournamentStateChange,
    (state, { data, tournamentId }) => {

      tournamentId = tournamentId || state.tournamentId

      const moderChatData = Object.keys(data)
      const activeUsers = moderChatData.map(
        id => ({
            id,
            userExtraId: data[id].userExtraId,
            unreadCount: getModeratorChatUnreadByChat(tournamentId, state, id, UnreadChatType.ModeratorChatMessage),
          }),
      )

      const activeId = state.tournamentsDirect[tournamentId]
        && state.tournamentsDirect[tournamentId].activeId
        || undefined

      return {
        ...state,
        tournamentsDirect: {
          ...state.tournamentsDirect,
          [tournamentId]: {
            activeUsers,
            diffWithPrevious: state.tournamentsDirect[tournamentId] && state.tournamentsDirect[tournamentId].chatData
                            ? compare(state.tournamentsDirect[tournamentId].chatData, data) 
                            : {},
            chatData: data,
            activeId,
            totalUnread: getTotalUnreadByTournament(tournamentId, state, UnreadChatType.ModeratorChatMessage) || 0,
          },
        },
      }
    }),


  on(ChatboxActions.loadReportsChatSuccess, (state, { tournamentId, data }) => {
    const activeDMUsers = []
    const directChains = {}
    let chain

    for (const key in data) {
      if (data.hasOwnProperty(key)) {

        activeDMUsers.push(({
          id: key,
          unreadCount: getModeratorChatUnreadByChat(tournamentId, state, key, UnreadChatType.SupportMessage),
        }))
        const rawChain = data[key]
        const directChainData: { [key: string]: DirectMessage } = {}

        for (const rawMsgId in rawChain) {
          if (rawChain.hasOwnProperty(rawMsgId)) {
            const rawMsg: RawReport = rawChain[rawMsgId]
            const normalizedDM: DirectMessage = { ...rawMsg }
            directChainData[rawMsgId] = normalizedDM
          }
        }

        chain = {
          userId: key,
          data: directChainData,
          lastMessage: Object.values(directChainData).map(({ createdDateTime }) => createdDateTime).sort()[0],
          hasUnread: false,
        }
        directChains[key] = chain
      }
    }

    const directData = {
      activeDMUsers, // TODO: rename to something report-related
      data: directChains,
      hasUnread: false,
      totalUnread: getTotalUnreadByTournament(tournamentId, state, UnreadChatType.SupportMessage) || 0,
      // hasUnread: (directChains).filter(val => val.hasUnread === true).length > 0,
    }

    return {
      ...state,
      lastReportsState: data,
      diffWithPrevious: compare(state.lastReportsState, data),
      directMsgData: {
        ...state.directMsgData,
        [tournamentId]: {
          ...(state.directMsgData && state.directMsgData[tournamentId] ? state.directMsgData[tournamentId] : {}),
          ...directData,
        },
      },
      hasUnread: false,
      // hasUnread: chain.data.filter(item => item.hasUnread === true).length > 0,
    }
  }),

  on(ChatboxActions.reportsSetActiveId,
    (state, { chatId }) =>
      ({
        ...state,
        directMsgData: {
          ...state.directMsgData,
          [state.tournamentId]: {
            ...state.directMsgData[state.tournamentId],
            activeId: chatId,
          },
        },
      }),
  ),

  on(ChatboxActions.onModeratorDirectTournamentSetActiveId,
    (state, { activeId }) => {

      const { tournamentsDirect, tournamentId } = state

      if (tournamentsDirect &&
        tournamentsDirect[tournamentId] &&
        tournamentsDirect[tournamentId].activeId &&
        tournamentsDirect[tournamentId].activeId === activeId) {
        return state
      }

      if (!tournamentsDirect[tournamentId]) {
        return state
      }

      const { activeUsers = {}, chatData = {}, totalUnread = 0, diffWithPrevious = deepDiffInitialState } = tournamentsDirect[tournamentId]

      return {
        ...state,
        tournamentsDirect: {
          ...tournamentsDirect,
          [tournamentId]: {
            activeId, activeUsers, chatData, totalUnread, diffWithPrevious,
          },
        },
      }
    }),


  on(ChatboxActions.updateUnreadState,
    (state) => {

      const { tournamentId, tournamentsDirect } = state

      if (tournamentId && tournamentsDirect && tournamentsDirect[tournamentId]) {
        const { activeUsers, chatData, activeId, diffWithPrevious } = tournamentsDirect[tournamentId]
        const updated = (activeUsers as any[]).map((user): any =>
          ({
            ...user as {},
            // tslint:disable-next-line:no-string-literal
            unreadCount: getModeratorChatUnreadByChat(tournamentId, state, user['id'], UnreadChatType.ModeratorChatMessage),
          }),
        )

        return {
          ...state,
          tournamentsDirect: {
            ...state.tournamentsDirect,
            [tournamentId]: {
              activeUsers: updated,
              chatData,
              diffWithPrevious,
              activeId,
              totalUnread: getTotalUnreadByTournament(tournamentId, state, UnreadChatType.ModeratorChatMessage) || 0,
            },
          },
        }
      }

      return state
    },
  ),

  on(ChatboxActions.updateUnreadReportsState,
    (state) => {

      const { tournamentId, directMsgData } = state

      if (tournamentId && directMsgData && directMsgData[tournamentId]) {

        const tournamentData = directMsgData[tournamentId]


        const { activeDMUsers } = tournamentData
        if (activeDMUsers) {
          const updatedActiveUsers = activeDMUsers.map(user => ({
            ...user, unreadCount: getModeratorChatUnreadByChat(tournamentId, state, user.id, UnreadChatType.SupportMessage),
          }))

          return {
            ...state,
            directMsgData: {
              ...state.directMsgData,
              [tournamentId]: {
                ...state.directMsgData[tournamentId],
                activeDMUsers: updatedActiveUsers,
                totalUnread: getTotalUnreadByTournament(tournamentId, state, UnreadChatType.SupportMessage) || 0,
              },
            },
          }
        }
      }

      return state
    },
  ),


  on(ChatboxActions.setModeratorActiveChat, (state, { chatId }) => ({
    ...state,
    moderatorActiveChat: chatId,
  })),

  on(markReadModeratorMessageSuccess, (state, { data }) => {

    const { moderatorChatList, moderatorActiveChat } = state
    const chat = moderatorChatList[moderatorActiveChat]
    const { currentParticipant } = chat

    return {
      ...state,
      moderatorChatList: {
        ...moderatorChatList,

        [moderatorActiveChat]: {
          ...chat,
          currentParticipant: {
            ...currentParticipant, messageRead: true,
          },

        },
      },
    }
  }),


  on(onMessage, (state, { evt }) => {

    const { moderatorChatList } = state

    const event = JSON.parse(evt.body)
    const { type, body } = event


    if (type === 'NEW_MESSAGE') {

      const { messages, currentParticipant } = moderatorChatList[body.globalChatId]

      return {
        ...state,
        moderatorChatList: {
          ...moderatorChatList,

          [body.globalChatId]: {
            ...moderatorChatList[body.globalChatId],
            currentParticipant: {
              ...currentParticipant,
              messageRead: false,
            },
            messages: [
              ...messages,
              body,
            ],
          },
        },
      }
    }

    return state
  }),

  on(ChatboxActions.fetchModeratorMessagesSuccess, (state, data: any) => {

    const { id, messages, participants } = data.data

    const moderatorChatListItem = {
      ...state.moderatorChatList[id],
      messages, participants,
    }

    return {
      ...state,
      moderatorChatList: {
        ...state.moderatorChatList,
        [id]: moderatorChatListItem,
      },
    }

  }),

  on(ChatboxActions.fetchModeratorChatListSuccess, (state, { data }) => {

    const moderatorChatList = {}
    data.map(item => moderatorChatList[item.id] = item)

    return { ...state, moderatorChatList }
  }),

  on(ChatboxActions.onTmMessage, (state, { message, tournamentId }) => {

    const toUpdate = { ...state.tmMessages[tournamentId], [message.time]: message }

    return ({
      ...state,
      tmMessages: {
        ...state.tmMessages,
        [tournamentId]: {
          ...toUpdate,
        },
      },
    })
  }),

  on(ChatboxActions.fetchTmMessagesSuccess, (state, { data, tournamentId }) => {
    const updated = {}
    const { tmMessages } = state

    data.map(msg => updated[msg.time] = msg)

    return {
      ...state,
      tmMessages: {
        ...tmMessages,
        [tournamentId]: updated,
      },
    }
  }),


  on(ChatboxActions.setOpen, (state, { open }) => ({ ...state, open })),
  on(ChatboxActions.toggleOpen, state => ({ ...state, open: !state.open })),

  on(ChatboxActions.setUserId, (state, { userId }) => ({ ...state, userId })),
  on(ChatboxActions.setTournamentId, (state, { tournamentId }) => ({ ...state, tournamentId })),

  on(ChatboxActions.setActiveTab, (state, { activeTab }) => {
    console.log('[Tab] setting tab: ', activeTab )
    return { ...state, activeTab }
  }),



  on(UnreadFeatureActions.loadUnreadInfoSuccess, (state, { data }) => {

    console.log('Updating Unread data!')

    const moderatorDirectUnreadData = normalizeUnreadTreeByTournaments(data, UnreadChatType.ModeratorChatMessage)
    const supportNormalizedData = normalizeUnreadTreeByTournaments(data, UnreadChatType.SupportMessage)

    return { ...state, unreadData: data, moderatorDirectUnreadData, supportNormalizedData }
  }),

)

const normalizeUnreadTreeByTournaments = (data, type: UnreadChatType) => {
  const temporary = {}
  if (data[type]) {
    const unreadData = data[type]

    const keys = Object.keys(unreadData)
    const tournaments = keys.map(
      rawKey => ({ tournamentId: getSplitKey(rawKey, 0), userId: getSplitKey(rawKey, 1), rawKey }),
    )

    const dashed = _.mapValues(_.groupBy(tournaments, 'tournamentId'))
    tournaments.map(t => temporary[t.tournamentId] = {})

    Object.keys(temporary).map(tournamentid => {
      dashed[tournamentid].map(({ rawKey, userId }) => {
        temporary[tournamentid][userId] = unreadData[rawKey]
      })
    })
  }
  return temporary
}


const isMsgUnread = (id: string, msg: RawReport) => {
  if (!msg.readBy) { return true }

  const history = Object.keys(msg.readBy)
  return history.indexOf(id) > 0
}


export function reducer(state: ChatboxState | undefined, action: Action) {
  return chatboxReducer(state, action)
}


enum DirectMessageType {
  Text = '10',
  Image = '20',
}


const getSplitKey = (str: string, i: number, separator = ':') => str.split(separator)[i]
const getTotalUnreadByTournament = (id: string, state, type: UnreadChatType): number => {

  if (type === UnreadChatType.ModeratorChatMessage) {

    const { moderatorDirectUnreadData } = state

    const tournament = moderatorDirectUnreadData[id]
    let total = 0
    for (const chat in tournament) {
      if (tournament.hasOwnProperty(chat)) {
        const el = tournament[chat]
        total += el.length
      }
    }
    return total

  }

  if (type === UnreadChatType.SupportMessage) {

    const { supportNormalizedData } = state

    const tournament = supportNormalizedData[id]
    let total = 0
    for (const chat in tournament) {
      if (tournament.hasOwnProperty(chat)) {
        const el = tournament[chat]
        total += el.length
      }
    }
    return total

  }

  return 0


}

const getModeratorChatUnreadByChat = (tournamentId: string, state, chatId, type: UnreadChatType): number => {

  if (type === UnreadChatType.ModeratorChatMessage) {

    if (state.moderatorDirectUnreadData) {
      const { moderatorDirectUnreadData } = state
      if (moderatorDirectUnreadData[tournamentId]) {

        const tournamentData = moderatorDirectUnreadData[tournamentId]

        if (tournamentData[chatId]) {
          return tournamentData[chatId].length
        }
      }
    }
  }

  if (type === UnreadChatType.SupportMessage) {

    if (state.supportNormalizedData) {
      const { supportNormalizedData } = state
      if (supportNormalizedData[tournamentId]) {

        const tournamentData = supportNormalizedData[tournamentId]

        if (tournamentData[chatId]) {
          return tournamentData[chatId].length
        }
      }

    }
  }
  return 0
}


// interface DirectMessagesData {
//   hasUnread?: boolean
//   data: DirectMessage[]
//   activeDMUsers: any[]
//   lastMessage?: string
//   activeId?: string
// }
interface DirectMessage extends RawReport {
  hasUnread?: boolean
}
interface RawReport {
  id: string
  content: string
  createdDateTime: string
  moderatorId: string
  moderatorSender: boolean
  senderAvatar: string
  senderNickName: string
  tournamentId: string
  readBy: {
    [key: string]: boolean,
  }
  type: DirectMessageType
  userExtraId: string
}
export interface RawReportsChain {
  [key: string]: RawReport
}
