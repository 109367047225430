import { Tournament } from './../../shared/model/tournament.model';
import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component'
import { TournamentService } from '../../../app/moderators/tournament.service'
import { selectUserId } from '../../auth/account.selectors'
import { AppState } from '../../reducers/index'
import { PushNotifierService } from '../../services/push-notifier.service'
import { imageAvatarFallback } from '../../utils/string.util'
import { selectIsBracketsInited } from '../timeline-feature.selectors'


@Component({
  selector: 'app-participants-item',
  templateUrl: './participants-item.component.html',
  styleUrls: ['./participants-item.component.scss'],
})
export class ParticipantsItemComponent implements OnInit {
  @Input() tournament: Tournament;
  @Input()
  participant

  @Input()
  tournamentId: string

  @Input()
  index

  hideParticipantActions: Observable<boolean>

  constructor(
    private tournamentService: TournamentService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private pushNotifier: PushNotifierService,
  ) { }

  dropdownOpened = false

  toggleOpen() {
    this.dropdownOpened = !this.dropdownOpened
  }


  onPushNotify( participant ) {

    const { id, firstName } = participant

    this.store.select(selectUserId)
    .pipe(
      take( 1 ),
    ).subscribe( moderatorId => {
        const dto = { user: { id, firstName }, moderatorId }
        this.pushNotifier.notifyPersonally(dto)
      })
  }

  checkInManually() {

    const confirm = this.dialog.open(ConfirmComponent,
      {
        data: {
          title: `Check in ${this.participant.nickname || 'participant'}`,
          content: 'Are you sure?',
        }, backdropClass: 'modal-blurred', panelClass: 'modal-no-padding',
      })

    confirm.afterClosed().subscribe(
      (res: boolean) => {
        if (res) {
          this.tournamentService.checkInManually(
            this.tournamentId,
            this.participant.id,
          )
            .subscribe(data => {
              console.log(data)
              // this.store.dispatch(fetchParticipants({ tournamentId: this.tournamentId }))
              })
        }
      })


  }

  unCheckin() {

    const confirm = this.dialog.open(ConfirmComponent,
      {
        data: {
          title: `Check out ${this.participant.nickname || 'participant'}`,
          content: 'Are you sure?',
        }, backdropClass: 'modal-blurred', panelClass: 'modal-no-padding',
      })

    confirm.afterClosed().subscribe(
      (res: boolean) => {
        if (res) {
          this.tournamentService.unCheckIn(
            this.tournamentId,
            this.participant.id,
          )
            .subscribe(data => {
              console.log(data)
              // this.store.dispatch(fetchParticipants({ tournamentId: this.tournamentId })
                                // )
               })
        }
      })

  }

  onKickOut() {


    const confirm = this.dialog.open(ConfirmComponent,
      {
        data: {
          title: `Kick out ${this.participant.nickname || 'participant'}`,
          content: 'Are you sure?',
        }, backdropClass: 'modal-blurred', panelClass: 'modal-no-padding',
      })

    confirm.afterClosed().subscribe(
      (res: boolean) => {
        if (res) {
          this.tournamentService.unJoin(this.tournamentId, this.participant.id)
            .subscribe(data => {
              // this.store.dispatch(
              //   fetchParticipants({ tournamentId: this.tournamentId }),
              // )
              console.log(data)})
        }
      })
  }

ngOnInit() {
  this.hideParticipantActions = this.store.select(selectIsBracketsInited)
}

handleAvatar(img) {
  return imageAvatarFallback(img)
}

}
