import { createAction, props } from '@ngrx/store'


export const subscribeTo = createAction(
  '[SocketManager] Subscribe To',
  props<{path: string}>(),
)

export const onMessage = createAction(
  '[SocketManager] On Socket Message',
  props<{ evt: any}>(),
)

export const onModeratorsSubscribed = createAction(
  '[SocketManager] On Moderators Subscribed Message',
)


export const connect = createAction(
  '[SocketManager] Socket connect',
)

export const connectSuccess = createAction(
  '[SocketManager] Socket connect Success',
)

export const connectFailure = createAction(
  '[SocketManager] Socket connect Failure',
  props<{ error: any }>(),
)

export const emptyAction = createAction(
  '[SocketManager] Empty',
)
