import { Observable } from 'rxjs'
import { AppConstants } from '../app.constants'
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ITournament } from '../shared/model/tournament.model'
import { map } from 'rxjs/operators'
import * as moment from 'moment'

type EntityResponseType = HttpResponse<ITournament>

@Injectable({ providedIn: 'root' })
export class TournamentsService {

    constructor(
        private http: HttpClient,
    ) { }

    getTournamentList(page: number, size: number, name: string): Observable<HttpResponse<any>> {
        return this.http.get(AppConstants.SERVER_API_URL + '/api/tournaments?name='+name+'&page=' + page + '&size=' + size+'&sort=id,desc', { observe: 'response' })
    }

    decideTheWinner(decideTheWinnerDTO: any): Observable<HttpResponse<any>> {
        // tslint:disable-next-line:max-line-length
        return this.http.post(AppConstants.SERVER_API_URL + '/api/match-handle/decideTheWinner', decideTheWinnerDTO, { observe: 'response' })
    }

    getTouramentParticipants(tournamentId: string, stageIndex: string, sectorIndex: string, status: string): Observable<HttpResponse<any>> {
        if (!stageIndex) { stageIndex = '' }
        if (!sectorIndex) { sectorIndex = '' }
        return this.http.
            // tslint:disable-next-line:max-line-length
            get(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}/getTournamentParticipants?status=${status}`, { observe: 'response' })
    }
    getTouramentMatches(tournamentId: string, stageIndex: string, sectorIndex: string): Observable<HttpResponse<any>> {
        if (!stageIndex) { stageIndex = '' }
        if (!sectorIndex || sectorIndex === '-1') { sectorIndex = '' }
        return this.http.
            // tslint:disable-next-line:max-line-length
            get(AppConstants.SERVER_API_URL + `/api/tour-matches/cmsTournamentMatches?tournamentId=${tournamentId}&stageIndex=${stageIndex}&sectorIndex=${sectorIndex}`, { observe: 'response' })
    }

    getModeratorNotifications(tournamentId: number, stageIndex?: any, sectorIndex?: any) {
        return this.http.
            // tslint:disable-next-line:max-line-length
            get(AppConstants.SERVER_API_URL + `/api/moderator-rest/getModeratorNotifications?tournamentId=${tournamentId}&status=2`, { observe: 'response' })
    }

    create(tournament: ITournament): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(tournament)
        return this.http
            .post<ITournament>(AppConstants.SERVER_API_URL + `/api/tournaments`, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)))
    }

    update(tournament: ITournament): Observable<EntityResponseType> {

        // debugger
        const copy = this.convertDateFromClient( tournament )
        // debugger
        return this.http
            .put<ITournament>(AppConstants.SERVER_API_URL + `/api/tournaments`, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)))
    }

    protected convertDateFromClient(tournament: ITournament): ITournament {
        const copy: ITournament = Object.assign({}, tournament, {
            regDateTimeOpen:
                tournament.regDateTimeOpen != null && tournament.regDateTimeOpen.isValid() ? tournament.regDateTimeOpen.toJSON() : null,
            regDateTimeClose:
                tournament.regDateTimeClose != null && tournament.regDateTimeClose.isValid() ? tournament.regDateTimeClose.toJSON() : null,
            startDateTime:
                tournament.startDateTime != null && tournament.startDateTime.isValid() ? tournament.startDateTime.toJSON() : null,
            initBracketDateTime:
                tournament.initBracketDateTime != null && tournament.initBracketDateTime.isValid()
                    ? tournament.initBracketDateTime.toJSON()
                    : null,
        })
        return copy
    }

    protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
        if (res.body) {
            res.body.regDateTimeOpen = res.body.regDateTimeOpen != null ? moment(res.body.regDateTimeOpen) : null
            res.body.regDateTimeClose = res.body.regDateTimeClose != null ? moment(res.body.regDateTimeClose) : null
            res.body.startDateTime = res.body.startDateTime != null ? moment(res.body.startDateTime) : null
            res.body.initBracketDateTime = res.body.initBracketDateTime != null ? moment(res.body.initBracketDateTime) : null
        }
        return res
    }

    // DELETE /api/tournaments/{id}
    deleteTournament(tournamentId: any): Observable<HttpResponse<any>> {
        // tslint:disable-next-line:max-line-length
        return this.http.delete(AppConstants.SERVER_API_URL + `/api/tournaments/${tournamentId}`, { observe: 'response' })
    }
}
