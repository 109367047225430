import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import * as _ from 'lodash'
import { of } from 'rxjs'
import { catchError, combineLatest, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as ChatboxActions from '../actions/chatbox.actions'
import { fetchModeratorChatList, markAsReadMessagesInfo } from '../actions/chatbox.actions'
import * as AccountSelectors from '../auth/account.selectors'
import { ChatboxHttpService } from '../chatbox/chatbox-http.service'
import { ChatboxTabs } from '../chatbox/models/ChatboxTabs.enum'
import { ModeratorsService } from '../moderators/moderators.service'
import * as ChatboxSelectors from '../selectors/chatbox.selectors'
// tslint:disable-next-line:max-line-length
import { getTournamentId, selectActiveModeratorsDirectTournamentId, selectModeratorDirectActiveChat, selectModeratorTournamentUnreadState, selectTmMessages, selectTmUnreadData } from '../selectors/chatbox.selectors';
import { WebSocketClient } from '../socket/socket-client-service'
import { emptyAction } from '../socket/socket-manager.actions'
import * as DataSelectors from '../tournament-data/store/tournament-data.selectors'
import { UnreadHttpService } from '../unread/services/unread-http.service'
import * as UnreadActions from '../unread/store/unread-feature.actions'
import { selectUnreadGlobalData } from '../unread/store/unread-feature.selectors'
import { AppState } from 'src/app/reducers'
import { selectTournamentId } from '../timeline/timeline-feature.selectors'


@Injectable()
export class ChatboxEffects {

notifyOnDirect = createEffect( () => {
    return this.actions$.pipe(
      ofType(ChatboxActions.onModeratorDirectTournamentStateChange),
      withLatestFrom(
        this.store.select(ChatboxSelectors.selectTournamentsDirect),
        this.store.select(DataSelectors.selectAllTournamentsNames),
        this.store.select(AccountSelectors.selectUserId),
    ),
    concatMap( ([{data, tournamentId }, tournamentsDirect, tournamentNames,  userId]) => {

      const direct = tournamentsDirect[tournamentId]
      const { diffWithPrevious: { missing_from_first } } = direct

      if ( missing_from_first ) {
        const [tournamentKey, chatKey ]  = missing_from_first

        // console.log(this)
        // if ( missing_from_first )

        const path = chatKey && chatKey.split && chatKey.split('.') || tournamentKey && tournamentKey.split && tournamentKey.split('.')
        console.log('[Direct] path: ', path)
        if ( path.length === 2 ) {
          const dto = _.at(data, chatKey || tournamentKey)

          const value: any = dto[0]

          const { id, senderAvatar, senderNickName, content, userExtraId, moderatorSender } = value

          if ( !moderatorSender ) {

            this.socketClient.openSnack(
              ChatboxTabs.Direct,
              { imageUrl: senderAvatar, firstName: senderNickName },
              content, id, tournamentId, tournamentNames[tournamentId].name || 'Direct message from Player',
            )
          }
        }
      }

      return of(emptyAction())
    },

    ),
    )
  })


notifyOnReports = createEffect( () => {

    return this.actions$.pipe(
      ofType(ChatboxActions.loadReportsChatSuccess),
      withLatestFrom(
        this.store.select(ChatboxSelectors.selectReportsDiff),
        this.store.select(DataSelectors.selectAllTournamentsNames),
        this.store.select(AccountSelectors.selectUserId),
    ),
    concatMap( ([{data}, { missing_from_first } , tournamentNames, userId]) => {

      if ( missing_from_first ) {
        const path = missing_from_first[0].split && missing_from_first[0].split('.')
        if ( path.length === 2 ) {
          const dto = _.at(data, missing_from_first[0])

          console.log('[Reports] notify! ', dto[0])
          const value = dto[0]

          const { id, senderAvatar, senderNickName, content, tournamentId,
            userExtraId, moderatorSender } = value

          if ( !moderatorSender ) {

            this.socketClient.openSnack(
              ChatboxTabs.Reports,
              { imageUrl: senderAvatar, firstName: senderNickName },
              content, id, tournamentId, tournamentNames[tournamentId].name,
            )
          }
        }
      }

      return of(emptyAction())
    },

    ),
    )
  })

markAsReadDirectSuccess$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(
        ChatboxActions.markAsReadMessagesInfoSuccess,
        // UnreadActions.loadUnreadInfoSuccess,
        // ChatboxActions.onModeratorDirectTournamentStateChange,
        // ChatboxActions.onModeratorDirect
        ),
      concatMap( () => of( UnreadActions.loadUnreadInfo() ) ),

    )
  })


updateUnread$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(
        UnreadActions.loadUnreadInfoSuccess,
        // UnreadActions.loadUnreadInfoSuccess,
        // ChatboxActions.onModeratorDirectTournamentStateChange,
        // ChatboxActions.onModeratorDirect
        ),
      concatMap( () => of(ChatboxActions.updateUnreadState(), ChatboxActions.updateUnreadReportsState()) ),

    )
  })

markAsReadAPI$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(markAsReadMessagesInfo),
      concatMap(({ dto }) => {

        return this.unreadHttp.markAsRead(dto).pipe(
          map(data => ChatboxActions.markAsReadMessagesInfoSuccess({ data })),
          catchError(error => of(ChatboxActions.markAsReadMessagesInfoFailure({ error }))),
        )
      }),

    )
  })

markReadCurrent



formMessagesReadArray = createEffect(() => {
    return this.actions$.pipe(

      ofType(ChatboxActions.markAsReadMessagesInfoByChatId),
      withLatestFrom(
        this.store.select(selectModeratorTournamentUnreadState),
      ),
      concatMap(([{ chatId }, messages]) => {

        let dto = []
        if ( messages && messages[chatId] ) {
          dto = messages[chatId].map(({ messageInfoKey }) => messageInfoKey)
        }

        return of(markAsReadMessagesInfo({ dto }))
      },
      ),
    )
  })

formMessagesReadSupportArray = createEffect(() => {
      return this.actions$.pipe(

        ofType(ChatboxActions.markAsReadSupportByChatId),
        withLatestFrom(
          // TODO: add check if the call is necessary or not
          this.store.select(ChatboxSelectors.selectSupportUnreadState),
        ),
        concatMap(([{ chatId }, messages]) => {

          let dto = []
          if ( messages && messages[chatId] ) {
            dto = messages[chatId].map(({ messageInfoKey }) => messageInfoKey)
          }
          return of(markAsReadMessagesInfo({ dto }))
        }),
      )
    })

formMarkReadGlobalChatDTO = createEffect(() => {
    return this.actions$.pipe(

      ofType(ChatboxActions.markReadModeratorMessage),
      withLatestFrom(
        // this.store.select(getTournamentId),
        this.store.select(selectUnreadGlobalData),
      ),
      concatMap(([{ chatId }, messages]) => {

        let dto = []
        if ( messages && messages[chatId] ) {
          dto = messages[chatId].map(({ messageInfoKey }) => messageInfoKey)
        }

        return of(markAsReadMessagesInfo({ dto }))
      },
      ),

    )
  })

formMarkReadModeratorSharedRoomDTO = createEffect(() => {
    return this.actions$.pipe(

      ofType(ChatboxActions.markReadSharedRoom),
      withLatestFrom(
        this.store.select(selectTournamentId),
        this.store.select(selectTmUnreadData),
      ),
      concatMap(([action, tournamentId, messages]) => {

        // debugger

        let dto = []
        if ( messages ) {
          dto = messages.map(({ messageInfoKey }) => messageInfoKey)
        }

        return of(markAsReadMessagesInfo({ dto }))
      },
      ),

    )
  })

loadUnreadFeatures$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(
        UnreadActions.loadUnreadInfo,
        ChatboxActions.onModeratorDirectTournamentStateChange,
        ChatboxActions.onTmMessage,
      ),
      concatMap(() =>

        this.unreadHttp.fetchUnreadInfo().pipe(
          map(data => UnreadActions.loadUnreadInfoSuccess({ data })),
          catchError(error => of(UnreadActions.loadUnreadInfoFailure({ error })))),
      ),
    )
  })



fetchModeratorsListAPI$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(ChatboxActions.fetchModeratorsList),
      concatMap((action) => {
        return this.moderators.getModeratorList(0, 100, 'ALL', undefined).pipe(
          map(data => ChatboxActions.fetchModeratorsListSuccess({ data })),
          catchError(error => of(ChatboxActions.fetchModeratorsListFailure({ error }))),
        )
      }),
    )
  })


sendModeratorDirectTournamentV2$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(ChatboxActions.sendModeratorDirectTournamentV2),
      withLatestFrom(
        this.store.select(getTournamentId),
        this.store.select(selectActiveModeratorsDirectTournamentId),
      ),
      concatMap(([{ toSend }, tournamentId, activeId]) => {

        return this.chatboxHttp.sendModerMessage(
          { ...toSend, tournamentId, userExtraId: activeId },
        ).pipe(
          map(data => ChatboxActions.sendModeratorDirectTournamentV2Success({ data })),
          catchError(
            error => of(ChatboxActions.sendModeratorDirectTournamentV2Failure({ error }),
          )),
        )
      }),
    )
  })


  // onModeratorDirectChatActivate = createEffect( () => {

  //   return this.actions$.pipe(
  //     ofType(ChatboxActions.setModeratorActiveChat),
  //     // filter(v => !!v.chatId ),
  //     withLatestFrom(this.store.select(selectIfActiveChatIsRead)),
  //     concatMap( (  [{ chatId }, isRead]  ) => {
  //       // concatMap(([{ content }, chatId]) => {
  //       console.log('yeboi', chatId, isRead)
  //       // return chatId !== latest ?
  //       return [
  //         ChatboxActions.markReadModeratorMessage( { chatId } ),
  //       ]
  //       // : []

  //     }),
  //   )
  // } )


  //  markReadModeratorAPI$ = createEffect(() => {
  //    return this.actions$.pipe(
  //   ofType(ChatboxActions.markReadModeratorMessage),
  //   switchMap( ( { chatId }) => {
  //    return this.chatboxData.markReadModeratorChat(  chatId ).pipe(
  //       map(data => ChatboxActions.markReadModeratorMessageSuccess({ data })),
  //       catchError(error => of(ChatboxActions.markReadModeratorMessageFailure({ error }))),
  //       )
  //     },
  //     ),
  //   )},
  // )


  // Fetch every moderator message chain



fetchModeratorMessages = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatboxActions.fetchModeratorChatListSuccess),
      switchMap(({ data }) =>
        [...data.map(chain => ChatboxActions.fetchModeratorMessages({ chatId: chain.id }))],
      ),
    )
  })

sendModeratorMessageAPI$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(ChatboxActions.sendModeratorMessage),
      withLatestFrom(this.store.select(selectModeratorDirectActiveChat)),
      concatMap(([{ content }, chatId]) => {


        return this.chatboxHttp.sendModeratorMessage(chatId, content).pipe(
          map(data => ChatboxActions.sendModeratorMessageSuccess({ data })),
          catchError(error => of(ChatboxActions.sendModeratorMessageFailure({ error }))),
        )
      }),
    )
  })


fetchModeratorMessagesByChatIdAPI$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(ChatboxActions.fetchModeratorMessages),
      concatMap(({ chatId, page, size }) => {

        return this.chatboxHttp.fetchModeratorMessagesByChat(chatId, page, size).pipe(
          map(data => ChatboxActions.fetchModeratorMessagesSuccess({ data })),
          catchError(error => of(ChatboxActions.fetchModeratorMessagesFailure({ error }))),
        )
      }),
    )
  })


fetchModeratorChatListEffect$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(fetchModeratorChatList),
      concatMap(() => {

        return this.chatboxHttp.fetchModeratorChatsList().pipe(
          map(data => ChatboxActions.fetchModeratorChatListSuccess({ data })),
          catchError(error => of(ChatboxActions.fetchModeratorChatListFailure({ error }))),
        )
      }),
    )
  })

afterTabOpen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatboxActions.setActiveTab),
      concatMap((action) => {
        return of(ChatboxActions.afterOpenTab(action))
      }),
      // share()
    )
  })

fetchTmMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatboxActions.fetchTmMessages),
      concatMap(({ tournamentId }) =>

        this.chatboxHttp.fetchTmMessages({ tournamentId }).pipe(
          map((data) => ChatboxActions.fetchTmMessagesSuccess({ data, tournamentId })),
          catchError(error => of(ChatboxActions.fetchTmMessagesFailure({ error })))),
      ),
    )
  })




constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<AppState>,
    private chatboxHttp: ChatboxHttpService,
    private moderators: ModeratorsService,
    private unreadHttp: UnreadHttpService,
    private socketClient: WebSocketClient,
  ) { }

}
