import { flatten } from '@angular/compiler'
import { Action, createReducer, on } from '@ngrx/store'
import { ITournament } from '../shared/model/tournament.model'
import { Stage } from './models/Stage'
import { MatchEventType } from './models/TournamentEvent.interface'
import { TournamentScreenTab } from './models/TournamentScreenTab.enum'
import * as TimelineActions from './timeline-feature.actions'
import { NavRoom, NavRoomsState, NavStagesState } from './tournament-matches-filter/tournament-matches-filter.component'

export const timelineFeatureFeatureKey = 'timelineFeature'

export interface TimelineState {

  tournamentId: string
  moderatorId: string
  stages: Stage[]
  tournamentData: ITournament
  rooms: any[]
  tourMatches: any[]
  participants: any // TODO: create interface for participants
  notifications: any

  activeTab: TournamentScreenTab

  navStagesState: NavStagesState
  navRoomsState: NavRoomsState

  isNavSynced: boolean
}

export const initialState: TimelineState = {
  tournamentId: 'undefined',
  moderatorId: undefined,
  tournamentData: undefined,
  stages: [],
  rooms: [],
  tourMatches: [],
  notifications: {},


  navStagesState: {},
  navRoomsState: {},


  isNavSynced: undefined,
  participants: [],

  activeTab: TournamentScreenTab.Timeline,

}

const timelineFeatureReducer = createReducer(
  initialState,

  on(TimelineActions.pushMismatchImage, (state, { node, imageUrl, matchId } ) => {


    const asObj = {}

    state.tourMatches.map( match => {
      if ( matchId === match.id.toString()) {
        // debugger
        match = {
          ...match,
          ['node' + node]: {
            ...match['node'+ node],
            inputPhoto: imageUrl,
          },
        }

        asObj[match.id] = match

      } else {
        asObj[match.id] = match
      }

    })

    const tourMatches = Object.values(asObj)

    return { ...state, tourMatches }
  } ),

  on(TimelineActions.setModeratorId, (state, { moderatorId }) => ({ ...state, moderatorId })),
  on(TimelineActions.setTournamentId, (state, { tournamentId }) => ({ ...state, tournamentId })),


  on(TimelineActions.setActiveTab, ( state, { tab }) => ({ ...state, activeTab: tab })),


  on(TimelineActions.fetchNotificationsSuccess, (state, { data } ) => {

      const notifications = {}
      data.map(item => notifications[item.id] = item )

      return { ...state, notifications }
    }),

  on(TimelineActions.fetchParticipantsSuccess, ( state, action ) => {
    const participants = {}
    action.data.map(participant => participants[participant.id] = participant )
    return {
      ...state,
      participants,
    }
  }),
  on(TimelineActions.addTournamentEvent, (state, action) => {

    const { tourMatches } = state

    const newMatches = tourMatches.map(tourMatch => {

      if (action.evt.tourMatchId === tourMatch.id) {

        return {
          ...tourMatch,
          events: (action.evt.event.type === MatchEventType.RESET_MATCH) ? [] : [...tourMatch.events, action.evt.event],
        }
      }
      return tourMatch
    })

    return {
      ...state,
      tourMatches: newMatches,
    }
  }),

  /**
   *  Loads stage data and creates navigation state
   */

  on(TimelineActions.loadStagesSuccess, (state, { data }) => {

    const stages = data.map(value => ({
      label: getStageLabel(value),
      value,
    }))

    const navStagesState: NavStagesState = {}

    data.map(value => navStagesState[value] = {
        label: getStageLabel(value),
        value,
        checked: true,
      },
    )

    return { ...state, stages, navStagesState }
  }),

  on(TimelineActions.toggleNavStageItem,
    ( state, { value } ) => {

      // actual value of updated item
      const checked = !state.navStagesState[value].checked
      const changed = {
        ...state.navStagesState[value],
        checked,
      }

      const navStagesState = {
        ...state.navStagesState,
        [value]: changed,
      }

      const asArr = Object.values(state.navRoomsState)
      const navRoomsState = {}
      // debugger
      if ( checked ) {
        asArr.map( (room: NavRoom) => {
          if (room.stageIndex === value) {
            navRoomsState[room.id] = { ...room, disabled: false, checked: true }
          } else {
            navRoomsState[room.id ] = { ...room }
          }

        })
      } else {
        asArr.map(
          (room: NavRoom) => {

            if (room.stageIndex === value ) {
              navRoomsState[room.id] = {...room, disabled: true }
            } else {
              navRoomsState[room.id] = { ...room }
            }
          },
        )
      }

      return {
        ...state,
        navStagesState,
        navRoomsState,
      }
    }),

  on(TimelineActions.toggleNavRoomItem, (state, { value } ) => state),

  on(TimelineActions.setNavStages,
    (state, action) => {
      console.log(action)

      return state
    },
  ),


  on(TimelineActions.loadRoomsSuccess, ( state, { data } ) => {

    const navRoomsState = {}
    data.map( ({ stageIndex, name, id }) => navRoomsState[ id ] = {
      stageIndex, name, id,
      checked: true,
      disabled: false,

    } )

    return ({
    ...state,
    rooms: data,
    navRoomsState,
    // navRoomsState: data.map(room => ({
    //   label: `${room.name} [${room.stageIndex}]`,
    //   value: room.id,
    //   stage: room.stageIndex,
    //   checked: true,
    //   disabled: false
    // })),


    tourMatches: flatten(
      data.map(
        room => room.tourMatches.map(match => match),
      ),
    ),
  })
  },

  ),


  on(TimelineActions.loadTourDataSuccess, (state, action) => ({ ...state, tournamentData: action.data })),
)

export function reducer(state: TimelineState | undefined, action: Action) {
  return timelineFeatureReducer(state, action)
}


export const getStageLabel = value => {
  switch (value) {
    case 'F':
      return 'Final'
    case 'SF':
      return 'Semi Final'
    case 'QF':
      return 'Quarter Final'
    case 'L16':
      return 'R 16'
    case '5':
      return 'R 32'
    case '4':
      return 'R 64'
    case '3':
      return 'R 128'
    case '2':
      return 'R 256'
    case '1':
      return 'R 512'
    default:
      return value
  }
}
