import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Params } from '@angular/router'
import { Store } from '@ngrx/store'
import * as _ from 'lodash'
import { from, Observable, Subscription } from 'rxjs'
import { AppConstants } from 'src/app/app.constants'
import { AccountService } from 'src/app/auth/account.service'
import { TourMatchService } from 'src/app/services/tour-match.service'
import * as ChatboxActions from '../../actions/chatbox.actions'
// tslint:disable-next-line:max-line-length
import { fetchTmMessages, markAsReadMessagesInfoByChatId, markReadModeratorMessage, onModeratorDirectTournamentSetActiveId, sendModeratorDirectTournamentV2, sendModeratorMessage, setActiveTab, setModeratorActiveChat, setTournamentId, setUserId, toggleOpen, markReadSharedRoom } from '../../actions/chatbox.actions';
import * as AccountSelectors from '../../auth/account.selectors'
import { selectIsHeadOrSuperModerator } from '../../auth/account.selectors'
import { ChatboxEffects } from '../../effects/chatbox.effects'
import { AppState } from '../../reducers'
import * as ChatboxSelectors from '../../selectors/chatbox.selectors'
// tslint:disable-next-line:max-line-length
import { getActiveTab, getOpen, selectActiveModeratorsChatMessages, selectActiveModeratorsDirectTournamentChat, selectActiveModeratorsDirectTournamentMessages, selectHasModeratorsChatUnread, selectModeratorDirectActiveChat, selectModeratorsDirectList, selectTmMessages, selectTmMessagesUnread } from '../../selectors/chatbox.selectors';
import { ModeratorFindModalComponent } from '../../shared/components/moderator-find/moderator-find-modal/moderator-find-modal.component'
import { selectIsBracketsInited, selectMatches } from '../../timeline/timeline-feature.selectors'
import { selectUnreadGlobalChatCount } from '../../unread/store/unread-feature.selectors'
import { ChatboxHttpService } from '../chatbox-http.service'
import { ActiveUser } from '../models/ActiveUser'
import { ChatboxTabs } from '../models/ChatboxTabs.enum'



@Component({
  selector: 'app-ajax-chatbox',
  templateUrl: './ajax-chatbox.component.html',
  styleUrls: ['./ajax-chatbox.component.scss'],
})

export class AjaxChatboxComponent implements OnInit, OnDestroy, AfterContentInit {

  constructor(
    protected db: AngularFireDatabase,
    private route: ActivatedRoute,
    private chatboxHttp: ChatboxHttpService,
    public accountService: AccountService,
    private store: Store<AppState>,
    private tourMatchService: TourMatchService,
    private chatHttp: ChatboxHttpService,
    private chatboxEffects: ChatboxEffects,
    private dialog: MatDialog,
  ) { }

  open: Observable<boolean>
  activeTab: Observable<ChatboxTabs>

  chatboxTabs = ChatboxTabs


  // tmMessages
  tmMessages: Observable<any>

  moderMessages
  moderActiveChat



  messages$: Subscription
  params$: Subscription

  activeUsers: any[] = []
  activeUsersComplex: ActiveUser[] = []
  activeReportsChat: number
  selectedP2PChat: number

  reportsMesagesState: any
  tournamentId: number

  usersOnline: any[] = []
  userOnlineSubs: any[] = []


  inputReportsChatModel = ''
  isReportsChatInputFocused = false
  objectKeys = Object.keys

  users$: Observable<any> = from(this.activeUsers)
  usersChangeSubscription: Subscription

  isHeadOrSuperModerator
  isHeadOrSuperModeratorSub$


  isInitialized
  currentMatch
  listStages
  listSectors
  tournamentMatchList
  sector
  stage


  ws

  realtimeDataChat
  // chatCount

  inputGamesChatModel = ''

  isGamesChatInputFocused = false

  account
  accountUserExtra

  p2pSectorState
  p2pStageState

  moderChatData
  moderChatDataSub$
  moderChatActiveUsers: ActiveUser[]
  activeDirectChatId

  inputDirectChatModel = ''
  isDirectChatInputFocused = false


  p2pMatches


  tmInputModel
  tmInputFocused
  tmInputDirectModel
  tmInputDirectFocused

  isBracketsInited

  moderatorsDirectList
  moderatorsDirectActiveChat
  moderatorsDirectActiveMessages

  moderatorsDirectActiveChatSub$
  moderatorsDirectActiveChatValue

  moderatorsDirectHasUnread

  globalChatUnreadState: Observable<any>

  tournamentsDirectV2$: Observable<any>
  torunamentsDirectV2Messages$: Observable<any>

  reportsDataV2$: Observable<any>
  reportsMessagesV2: Observable<any>
  reportsActiveId: Observable<string | undefined>
  reportsActiveIdSub

  compareId = compareId

  tournamentGlobalRoomUnreadCount: Observable<number>


  userIdV2: Observable<string>


  // initTMSocketApi(tournamentId) {

  //   console.log('[WS]: connecting to: ', `/${tournamentId}/match-events`)
  //   this.ws = new WebSocketAPI([
  //     {
  //       path: `/${tournamentId}/moderator-chat/CREATE/`,
  //     },
  //   ])

  //   this.ws._connect()

  //   this.ws.getRecieveWebSocketData().subscribe(message => {

  //     const evt = JSON.parse(message.data.body)
  //     this.store.dispatch(onTmMessage({message: evt}))
  //   })
  // }


  handleSetModeratorActiveChat( id: string | undefined ) {

    if ( id !== this.moderatorsDirectActiveChatValue  ) {

      this.store.dispatch(setModeratorActiveChat({ chatId : id }))
      if ( !!id ) {
        this.store.dispatch( markReadModeratorMessage( { chatId: id }) )
      }

    }

    if (!id) {
      this.store.dispatch(markReadSharedRoom())
    }
  }

  getAnotherParticipant(participants, currentUser) {
    participants = participants || []
    return participants.find(( { id } ) => currentUser.id !== id )
  }

  ngOnInit() {

    this.tournamentsDirectV2$ = this.store.select(selectActiveModeratorsDirectTournamentChat)
    this.torunamentsDirectV2Messages$ = this.store.select(selectActiveModeratorsDirectTournamentMessages)

    this.reportsDataV2$ = this.store.select(ChatboxSelectors.selectReportsByActiveTournament)
    this.reportsActiveId = this.store.select(ChatboxSelectors.selectReportsActiveId)
    this.reportsMessagesV2 = this.store.select(ChatboxSelectors.selectReportsActiveMessages)
    this.reportsActiveIdSub = this.store.select(ChatboxSelectors.selectReportsActiveId).subscribe( id => this.activeReportsChat = id )

    this.globalChatUnreadState = this.store.select(selectUnreadGlobalChatCount)

    this.p2pMatches = this.store.select(selectMatches)
    this.isBracketsInited = this.store.select(selectIsBracketsInited)

    this.tournamentGlobalRoomUnreadCount = this.store.select(selectTmMessagesUnread)

    this.isHeadOrSuperModeratorSub$ = this.store.select(selectIsHeadOrSuperModerator).subscribe(
      value => this.isHeadOrSuperModerator = value,
    )

    this.chatboxEffects.afterTabOpen$.subscribe(
      ({ activeTab, gamesMatch }) => {

        if (activeTab === ChatboxTabs.Games && !!gamesMatch ) {
          this.handleOpenP2PChat(gamesMatch)
        }
      },
    )

    this.moderatorsDirectList = this.store.select(selectModeratorsDirectList)
    this.moderatorsDirectActiveChat = this.store.select(selectModeratorDirectActiveChat)
    this.moderatorsDirectActiveMessages = this.store.select(selectActiveModeratorsChatMessages)
    this.moderatorsDirectHasUnread = this.store.select(selectHasModeratorsChatUnread)

    this.moderatorsDirectActiveChatSub$ = this.store.select(selectModeratorDirectActiveChat).subscribe(
      value => this.moderatorsDirectActiveChatValue = value,
    )

    this.tmMessages = this.store.select(selectTmMessages)
    this.open = this.store.select(getOpen)
    this.activeTab = this.store.select(getActiveTab)

    this.store.dispatch(setUserId({ userId: this.accountService.getUserId() }))

    this.userIdV2 = this.store.select(AccountSelectors.selectUserId)


    this.params$ = this.route.params.subscribe(({ id: tournamentId = false }: Params) => {
      if (tournamentId) {
        this.tournamentId = tournamentId
        // this.initMessagesSub(tournamentId) // TODO: remove me
        this.store.dispatch(setTournamentId({ tournamentId }))
        this.store.dispatch(fetchTmMessages({tournamentId}))

        // this.initTMSocketApi(tournamentId)
        // this.initModerChatData(tournamentId)
      }
    })

  }

  // subscribeToUserStatus(userid: number) {

  //   return this.db.object(`users/${userid}/active`).valueChanges().subscribe((data: number) => {
  //     console.log(
  //       `User ${userid} active status is: `, data,
  //     )

  //     const i = _.findIndex(this.activeUsersComplex, (activeUser: ActiveUser) => compareId(activeUser.id, userid))

  //     this.activeUsersComplex[i].onlineStatus = data

  //   })

  // }

  tabClickHandler(activeTab: ChatboxTabs) {
    this.store.dispatch(setActiveTab({ activeTab }))
  }


  getOnlineStatus(id: number) {
    return this.activeUsersComplex.find((user: ActiveUser) => this.compareId(id, user.id) ).onlineStatus
  }

  activeUsersSorted() {
    // tslint:disable-next-line:radix
    return _.sortBy(this.activeUsersComplex, val => parseInt(val.lastMessage))
  }

  // initMessagesSub(id: number) {

    // this.tournamentId = id

    // this.messages$ = this.db.object(`tournaments_extra/${id}/support_messages`).valueChanges()
    //   .pipe(filter(x => !!x === true))
    //   .subscribe((value: RawDirectMessageChain) => {

    //     this.store.dispatch(loadReportsChatSuccess({ data: value, tournamentId: id.toString() }))
    //     this.activeUsers = Object.keys(value)

    //     if (!arraysEqual(this.activeUsers, this.usersOnline)) {

    //       this.usersOnline = this.activeUsers


    //       this.activeUsersComplex = this.activeUsers.map(
    //         userid => ({
    //           id: userid,
    //           onlineStatus: 0,

    //           lastMessage: Object.keys(value[userid]).sort()[Object.keys(value[userid]).length - 1],
    //         }),
    //       )
    //       this.unsubscribeFromOnline()
    //       this.userOnlineSubs = this.usersOnline.map(userid => this.subscribeToUserStatus(userid))

    //       this.store.dispatch(loadUnreadInfo())
    //       console.log('Online list users state changed;')
    //     }

    //     // this.reportsMesagesState = value
    //   })
  // }

  ngAfterContentInit(): void {
    this.isInitialized = true
  }



  // TODO: check if we can remove it
  handleOpenP2PChat(match) {
    const { id } = match

    this.selectedP2PChat = id
    this.currentMatch = match
    this.initGamesChat(id)
  }

  p2pAsArray2(data) {
    const arr = []
    if (data) {
      const keys = Object.keys(data)
      return (keys.map(key => data[key]))
    }
    return arr
  }

  initGamesChat(tourMatchId) {

    const link = 'tournaments/' + this.tournamentId + '/' + tourMatchId + '/messages'
    console.log('games chat data subscribed to ', link)
    this.db
      // 'tournaments/' + tournamentId + '/' + tourMatchId + '/messages' | 'tournaments/115/581/messages'
      .object(link)
      .valueChanges()
      .subscribe(items => {
        this.realtimeDataChat = items
        // this.chatCount = this.realtimeDataChat ? Object.keys(this.realtimeDataChat).length : 0
      })
  }


  // initModerChatData(id) {

  //   const link = `tournaments/${id}/users/${this.isHeadOrSuperModerator ? 'head_mod' : this.account.id}/moderator_chat`
  //   console.log('moderators data subscribed to ', link)
  //   this.moderChatDataSub$ = this.db
  //   .object(link).valueChanges().pipe(filter(v => !!v))
  //   .subscribe(
  //     chatData => {

  //       console.log( 'chatdata changed', chatData)
  //       // debugger
  //       this.store.dispatch(
  //         onModeratorDirectTournamentStateChange(
  //         { data: chatData },
  //         ),
  //       )
  //     },
  //   )
  // }


  chatOpenHandler(): void {
    this.store.dispatch(toggleOpen())
  }

  // TODO: replace with global handler
  imageLinkHandler(url: string): string {
    return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
  }

  unsubscribeFromOnline() {
    this.userOnlineSubs.map(sub => sub.unsubscribe())
  }

  ngOnDestroy(): void {
    // TODO: refactor to takeUntil()
    this.unsubscribeFromOnline()
    this.params$.unsubscribe()
    
    // this.messages$.unsubscribe()
  }




  /** Chat field handlers */
  // Moderators Chat
  tmInputFocusedHandler(focused: boolean) {
    this.tmInputFocused = focused
  }

  tmSendMessageHandler() {
    this.tmInputModel.trim()
    if (this.tmInputModel === '') { return }

    this.chatHttp.sendTmMessage(this.tournamentId, this.tmInputModel)
      .subscribe(res => {
        console.log(res)
      })
    this.tmInputModel = ''
  }

  tmInputDirectFocusedHandler(focused: boolean) {
    this.tmInputDirectFocused = focused
  }

  tmSendDirectMessageHandler() {
    this.tmInputDirectModel.trim()
    if (this.tmInputDirectModel === '') { return }


    this.store.dispatch(sendModeratorMessage( { content: this.tmInputDirectModel } ))
    this.tmInputDirectModel = ''
  }

  // Direct Chat

  onSendDirectChat() {
    this.inputDirectChatModel.trim()
    if (this.inputDirectChatModel.trim().length > 0) {

      const toSend = {
        content: this.inputDirectChatModel,
        type: 10,
      }

      this.store.dispatch(sendModeratorDirectTournamentV2( { toSend }))
      // this.chatboxHttp.sendModerMessage(toSend).subscribe()
      this.inputDirectChatModel = ''
    }
  }
  inputDirectChatFocusHandler(val: boolean) {
    this.isDirectChatInputFocused = val
  }
  setActiveDirectChat(id) {
    this.store.dispatch(onModeratorDirectTournamentSetActiveId({ activeId: id}))
    this.store.dispatch(markAsReadMessagesInfoByChatId({chatId: id}))
  }


  // Games Chat
  onSendGamesChat() {
    if (this.inputGamesChatModel) {
      this.tourMatchService
        .userChatInGame({
          tournamentId: this.tournamentId,
          tourMatchId: this.currentMatch.id,
          content: this.inputGamesChatModel,
          type: 10,
        })
        .subscribe( res => console.log(res) )
      this.inputGamesChatModel = ''
    }
  }

  inputGamesChatFocusHandler(bool) {
    this.isGamesChatInputFocused = bool
  }

  // Reports Chat

  onSendReportsChat() {

    this.inputReportsChatModel.trim()
    if (this.inputReportsChatModel === '') { return }

    const dto = {
      content: this.inputReportsChatModel,
      tournamentId: this.tournamentId,
      userExtraId: this.activeReportsChat,
    }

    this.chatboxHttp.sendMessage(dto).subscribe()
    this.inputReportsChatModel = ''
  }

  inputReportsChatFocusedHandler(bool) {
    this.isReportsChatInputFocused = bool
  }

  setActiveReportsChat(chatId) {

    // this.activeReportsChat = chatId // remove
    this.store.dispatch(ChatboxActions.reportsSetActiveId({ chatId }))
    this.store.dispatch(ChatboxActions.markAsReadSupportByChatId({ chatId }))

  }

  onFindModeratorModal() {
    const dialogRef = this.dialog.open(
      ModeratorFindModalComponent,
      {
        backdropClass: 'modal-blurred',
        panelClass: 'modal-no-padding',
      },
    )
  }
}



const compareId = (id1, id2) =>  {
  return id1.toString() === id2.toString()
}





// interface SupportMessagesDTO {
//   [key: number]: {                        // Player id
//     [key: number]: SupportMessageItem,     // Message id
//   }
// }

// interface SupportMessageItem {
//   content: string
//   createdDateTime: string
//   id: number
//   moderatorSender: boolean
//   senderAvatar: string
//   senderNickName: string
//   tournamentId: number
//   type: number
//   userExtraId: number
//   readBy: {
//     [key: number]: boolean,
//   }
// }

const arraysEqual = (arr1: any[], arr2: any[]): boolean => _.isEqual(_.sortBy(arr1), _.sortBy(arr2))

