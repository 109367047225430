import { createAction, props } from '@ngrx/store'
import { TournamentEvent } from './models/TournamentEvent.interface'
import { TournamentScreenTab } from './models/TournamentScreenTab.enum'


// Init

export const setTournamentId = createAction('[TimelineFeature] Set id', props<{ tournamentId: string }>())
export const setModeratorId = createAction('[TimelineFeature] Set id', props<{ moderatorId: string }>())


export const pushMismatchImage = createAction(
  '[TimelineFeature] Push Mismatch image',
  props<{ imageUrl: string, node: string, matchId: string }>(),
  )



export const fetchParticipants = createAction(
  '[TimelineFeature] FetchParticipants',
  props<{
    tournamentId: string,
    stageIndex?: string,
    sectorIndex?: string,
    status?: string,
  }>())

export const fetchParticipantsSuccess = createAction(
    '[TimelineFeature] FetchParticipantsSuccess',
     props<{data: any}>(),
)

export const fetchParticipantsFailure = createAction(
    '[TimelineFeature] FetchParticipantsFailure',
     props<{error: any}>(),
)

export const fetchNotifications = createAction(
  '[TimelineFeature] FetchNotifications',
)

export const fetchNotificationsSuccess = createAction(
    '[TimelineFeature] FetchNotificationsSuccess',
     props<{data: any}>(),
)

export const fetchNotificationsFailure = createAction(
    '[TimelineFeature] FetchNotificationsFailure',
     props<{error: any}>(),
)

export const setActiveTab = createAction(
  '[TimelineFeature] Set Active tab',
  props<{tab: TournamentScreenTab}>(),
)

export const resetMatch = createAction(
  '[TimelineFeature] Reset Match',
  props<{matchId: string}>(),
)

export const resetMatchSuccess = createAction(
  '[TimelineFeature] Reset Match Success',
  props<{data: any}>(),
)

export const resetMatchFailure = createAction(
  '[TimelineFeature] Reset Match Failure',
  props<{error: any}>(),
)

export const cancelMatch = createAction(
  '[TimelineFeature] Cancel Match',
  props<{matchId: string}>(),
)

export const cancelMatchSuccess = createAction(
  '[TimelineFeature] Cancel Match Success',
  props<{data: any}>(),
)

export const cancelMatchFailure = createAction(
  '[TimelineFeature] Cancel Match Failure',
  props<{error: any}>(),
)


export const addTournamentEvent = createAction(
  '[TimelineFeature] Add Tournament event',
  props<{evt: TournamentEvent}>(),
)


export const setNavStages = createAction(
  '[TimelineFeature] SetNavStages',
  props<{stage: any}>(),
)


export const loadRooms = createAction(
  '[TimelineFeature] Load Rooms',
  props<{ stages: string[], tournamentId: string }>(),
)

export const loadRoomsSuccess = createAction(
  '[TimelineFeature] Load Rooms Success',
  props<{ data: any[] }>(),
)

export const loadRoomsFailure = createAction(
  '[TimelineFeature] Load Rooms Failure',
  props<{ error: any }>(),
)

export const loadStages = createAction(
  '[TimelineFeature] Load Stages',
  props<{ tournamentId: string }>(),
)

export const loadStagesSuccess = createAction(
  '[TimelineFeature] Load Stages Success',
  props<{ data: any[] }>(),
)

export const loadStagesFailure = createAction(
  '[TimelineFeature] Load Stages Failure',
  props<{ error: any }>(),
)


export const loadTourData = createAction(
  '[TimelineFeature] Load Tour data',
  props<{ tournamentId: string}>(),
)

export const loadTourDataSuccess = createAction(
  '[TimelineFeature] Load Tour data Success',
  props<{ data: any }>(),
)

export const loadTourDataFailure = createAction(
  '[TimelineFeature] Load Tour data Failure',
  props<{ error: any }>(),
)


export const clickStartAction = createAction(
  '[TimelineFeature] Click Start Action',
  props<{ userId: string, matchId: string }>(),
)

export const clickStartActionSuccess = createAction(
  '[TimelineFeature] Click Start Action Success',
  props<{ data: any }>(),
)

export const clickStartActionFailure = createAction(
  '[TimelineFeature] Click Start Action Failure',
  props<{ error: any }>(),
)

export const clickFinishAction = createAction(
  '[TimelineFeature] Click Finish Action',
  props<{ userId: string, matchId: string }>(),
)

export const clickFinishActionSuccess = createAction(
  '[TimelineFeature] Click Finish Action Success',
  props<{ data: any }>(),
)

export const clickFinishActionFailure = createAction(
  '[TimelineFeature] Click Finish Action Failure',
  props<{ error: any }>(),
)

export const toggleNavStageItem = createAction(
  '[TimelineFeature] Toggle Nav Stage Item',
  props<{ value: string }>(),
)

export const toggleNavRoomItem = createAction(
  '[TimelineFeature] Toggle Nav Stage Item',
  props<{ value: string }>(),
)

