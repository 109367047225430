import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { MatDialog } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as _ from 'lodash'
import { Observable, Subject } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { AppConstants } from 'src/app/app.constants'
import { setActiveTab, toggleOpen, setOpen } from '../../actions/chatbox.actions'
import { AccountService } from '../../auth/account.service'
import { ChatboxTabs } from '../../chatbox/models/ChatboxTabs.enum'
import { AppState } from '../../reducers'
import { ConfirmComponent } from '../../shared/components/confirm/confirm.component'
import { EditResultModalComponent } from '../../tournaments/edit-result-modal/edit-result-modal.component'
import { TournamentMatchPunishCardComponent } from '../../tournaments/tournament-match-punish-card/tournament-match-punish-card.component'
import { CARD_TYPE } from '../../tournaments/tournament.constants'
import { cancelMatch, clickFinishAction, clickStartAction, resetMatch } from '../timeline-feature.actions'
import { selectIsBracketsInited, selectTournamentPlaying, selectTournamentStatus } from '../timeline-feature.selectors'

@Component({
  selector: 'app-ajax-timeline-item',
  templateUrl: './ajax-timeline-item.component.html',
  styleUrls: ['./ajax-timeline-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 })),
          ],
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 })),
          ],
        ),
      ],
    ),
  ],
})
export class AjaxTimelineItemComponent implements OnInit, OnDestroy {

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private store: Store<AppState>,
    private account: AccountService,
  ) { }

  @Input() match: any
  @Input() currentStageIndex: string

  optionsOpened = false
  matchFireSub$
  matchFireData
  user1FireSub$
  user2FireSub$

  userAOnline
  userBOnline

  tournamentId
  userId

  isBracketsInited

  hasEditResultState: boolean
  tournamentStatus: Observable<any>
  tournamentPlaying: Observable<any>

  destroy$: Subject<boolean> = new Subject<boolean>()

  isMatchActionsActive(match, tournamentStatus, playing) {
    // tslint:disable-next-line
    if ( tournamentStatus == 0 )                      // tournament not started
    return false

    // tslint:disable-next-line
    if ( tournamentStatus == 2 )                      // tournament is finished
      return false

    // tslint:disable-next-line
    if (playing !== match.stageIndex)          // playing other stage
      return false

    // tslint:disable-next-line
    if ( this.isBye(match.nodeA) )                          // one player is bye
      return false

    // tslint:disable-next-line
    if (this.isBye(match.nodeB))                            // one player is bye
      return false

    return true
  }

  isBye( node: any ): boolean {

    if (node.nodeIsByte ) {
      return true
    }
    if ( this.match.status.toString() === '2' && node.userExtraId === null) {
      return true
    }

    return false

  }

  genClassFlag(isoCode: string) {
    return isoCode ? 'flag-icon-' + isoCode.toLowerCase() : 'flag-icon-gb'
  }

  isMatchActive() {
    return this.currentStageIndex === this.match.stageIndex
  }

  openP2PChatRooms() {

    this.store.dispatch(setOpen({ open: true}))

    this.store.dispatch(setActiveTab({ activeTab: ChatboxTabs.Games, gamesMatch: this.match }))
  }


  initiateDirectChat(receiverId) {

    // tournamentId, receiverId

    if (!receiverId) {
      return
    }

    let isAlreadyInitiated

    const endpoint = `/tournaments/${this.tournamentId}/users/${this.userId}/moderator_chat/`
    const sub = this.db.object(endpoint).valueChanges().pipe(
      take(1),
    ).subscribe( val => {

      if (!val) {
        initiateChat(endpoint, receiverId)
      }

      const initiatedChats = Object.keys(val)
      isAlreadyInitiated = initiatedChats.indexOf(receiverId.toString()) > -1


      if (isAlreadyInitiated) {
        openChat()
      } else {
        initiateChat(endpoint, receiverId)
      }
    })


    const openChat = () => {
      this.store.dispatch(toggleOpen())
      this.store.dispatch(setActiveTab({ activeTab: ChatboxTabs.Direct }))
    }

    const initiateChat = (path, id) => {
      this.db.object(path).update(
        { [id]: true },
      ).then(fulfulled => {
        openChat()
        this.optionsOpened = false
        //  console.log('something is set!', fulfulled)
      })
    }

  }


  // tournamentStartSub$: Subscription

  ngOnInit() {

    this.isBracketsInited = this.store.select(selectIsBracketsInited)
    this.tournamentStatus = this.store.select(selectTournamentStatus)
    this.tournamentPlaying = this.store.select(selectTournamentPlaying)
    // this.tournamentStartSub$ = this.store.select(selectTournamentStartDate)



    this.userId = this.account.getUserId()
    const { tournamentId, id, nodeA, nodeB } = this.match
    this.tournamentId = tournamentId
    this.matchFireSub$ = this.db.object(`/tournaments/${tournamentId}/${id}`)
      .valueChanges().pipe(filter(v => !!v))
      .subscribe((matchData: any) => {
        this.matchFireData = matchData

        this.hasEditResultState = matchData.manageMatchStage === '5.0.0'

      })

    // TODO: Drop this functionality
    this.user1FireSub$ = this.db.object(`/users/${nodeA.userExtraId}`)
      .valueChanges().subscribe((user: any) => this.userAOnline = user && user.active > 0)

    this.user2FireSub$ = this.db.object(`/users/${nodeB.userExtraId}`)
      .valueChanges().subscribe((user: any) => this.userBOnline = user && user.active > 0)
  }


  onEditResultRequest() {

    const data = {
      matchId: this.match.id,
      tournamentId: this.tournamentId, // pass from store
    }

    const dialogRef = this.dialog.open(EditResultModalComponent,
      {
        data, panelClass: 'modal-no-padding', backdropClass: 'modal-blurred',
      })

    dialogRef.afterClosed().subscribe(result => console.log('result: ', result))
  }

  onClickPlayerYellowCard(tourMatchId, userExtraId, userExtraImageUrl, userExtraNickName) {
    const dialogRef = this.dialog.open(
      TournamentMatchPunishCardComponent,
      {
        data: {
          tournamentId: this.tournamentId,
          tourMatchId,
          card: true,
          cardType: CARD_TYPE.YELLOW,
          player: {
            userExtraId,
            userExtraImageUrl,
            userExtraNickName,
          },
        },
        panelClass: 'modal-no-padding',
        backdropClass: 'modal-blurred',
      })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('onClickPlayerOut dialogRef result:::', result)
      if (result) {
        console.log('Result! ', result)
        // this.tourMatchesReset.emit(true);
      } else {
        // this.tourMatchesReset.emit(false);
      }
    })
  }

  onClickPlayerRedCard(tourMatchId, userExtraId, userExtraImageUrl, userExtraNickName) {
    const dialogRef = this.dialog.open(
      TournamentMatchPunishCardComponent,
      {
        data: {
          tournamentId: this.tournamentId,
          tourMatchId,
          card: true,
          cardType: CARD_TYPE.RED,
          player: {
            userExtraId,
            userExtraImageUrl,
            userExtraNickName,
          },
        },
        panelClass: 'modal-no-padding',
        backdropClass: 'modal-blurred',
      })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('onClickPlayerOut dialogRef result:::', result)
      if (result) {
        console.log('Result! ', result)
        // this.tourMatchesReset.emit(true);
      } else {
        // this.tourMatchesReset.emit(false);
      }
    })
  }

  onClickKickOut(tourMatchId, userExtraId, userExtraImageUrl, userExtraNickName) {

    const dialogRef = this.dialog.open(
      TournamentMatchPunishCardComponent,
      {
        data: {
          tournamentId: this.tournamentId,
          tourMatchId,
          player: {
            userExtraId,
            userExtraImageUrl,
            userExtraNickName,
          },
        },
        panelClass: 'modal-no-padding',
        backdropClass: 'modal-blurred',
      })

    dialogRef.afterClosed().subscribe( result => {
      console.log('onClickPlayerOut dialogRef result:::', result)
      if (result) {
        console.log('Result! ', result)
      }
    })

  }

  toggleOptions() {
    this.optionsOpened = !this.optionsOpened
  }

  getEventsByTime() {
    return this.match && this.match.events ?
      _.groupBy(this.match.events, 'type') :
      {}
  }

  getEvent(type, node) {
    return  this.match && this.match.events.filter(value => ((type === value.type) && (value.node === node)))[0]
  }

  getEventsByType(type, node?) {

    if (node) {
      // debugger
      return this.match && this.match.events.filter(value => ((type === value.type) && (value.node === node)))
    }

    return this.match && this.match.events.filter(value => type === value.type)
  }

  askConfirm(action, payload) {
    const dialogRef = this.dialog.open( ConfirmComponent,
      {
        data: { title: 'Action instead of player', content: `Are you sure?` },
        panelClass: 'modal-no-padding', backdropClass: 'modal-blurred',
      })
    dialogRef.afterClosed().subscribe(result => {
      if (result) { this.store.dispatch(action(payload)) }
    })
  }

  onClickEventStart(nodeKey: string): void {

    const matchId = this.match.id
    const userId = this.match[nodeKey].userExtraId

    this.askConfirm( clickStartAction, { matchId, userId } )
  }

  onClickEventFinish(nodeKey: string) {

    const matchId = this.match.id
    const userId = this.match[nodeKey].userExtraId

    this.askConfirm( clickFinishAction, { matchId, userId } )
  }

  getStatusColor() {
    const { manageMatchStage = '' } = this.matchFireData

    switch (manageMatchStage) {
      case '5.0.0':
        return 'colored-red'
      case '4.0.0':
        return 'colored-yellow'
      default:
        return ''
    }
  }

  getStatusMessage() {
    const { manageMatchStage = '' } = this.matchFireData

    if ( this.match.status && this.match.status.toString() === '2' ) {
      return 'Match is finished'
    }

    switch (manageMatchStage) {
      case '6.0.0':
        return 'Match is finished'
      case '5.0.0':
        return 'Results not match'
      case '4.0.0':
        return 'Entering score'
      case '3.0.0':
        return 'Live'
      case '2.0.0':
        return 'Match can be started'
      case '1.5.0':
        return 'Pause between stages'
      case '1.0.0':
        return 'Not started yet'
      default:
        return ''
    }
  }

  onResetMatchClick(matchId) {

    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        data: {
          title: 'Reset match',
          content: 'Are you sure?',

        },
        backdropClass: 'modal-blurred',
        panelClass: 'modal-no-padding',
      },
    )

    dialogRef.afterClosed().subscribe((data: boolean) => data && this.store.dispatch(resetMatch({ matchId })))

  }

  onCancelHandler(matchId) {

    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        data: {
          title: 'Cancel match',
          content: 'Are you sure?',
        },
        backdropClass: 'modal-blurred',
        panelClass: 'modal-no-padding',
      },
    )

    dialogRef.afterClosed().subscribe((data: boolean) => data && this.store.dispatch(cancelMatch({ matchId })))
  }

  ngOnDestroy() {
    if (this.matchFireSub$) { this.matchFireSub$.unsubscribe() }
  }

  imageLinkHandler(url): string {
    if (url === null) { return 'assets/images/no-ava.png' }
    return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
  }
}
