import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { RouterModule, Routes } from '@angular/router'
import { SharedModule } from '../shared/share.module'
import { TutorialsCreateComponent } from './tutorials-create/tutorials-create.component'
import { TutorialsComponent } from './tutorials.component'
import { MatPaginatorIntl } from '@angular/material';
import { MatPaginatorIntlTutorialsList } from '../shared/components/ajax-paginator.provider'




const routes: Routes = [
  { path: '', component: TutorialsComponent },
  { path: 'new', component: TutorialsCreateComponent },
  { path: ':id/edit', component: TutorialsCreateComponent },
  // { path: 'detail/:id', component: TournamentLiveComponent }
]

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),

    MatPaginatorModule,
  ],
  declarations: [
    TutorialsComponent,
    TutorialsCreateComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlTutorialsList,
    },
  ],

  entryComponents: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TutorialsModule { }
