import { NgModule } from '@angular/core'
import { MinuteSecondsPipe } from './minutesSecond.pipe'

@NgModule({
    imports: [
    ],
    declarations: [
        MinuteSecondsPipe,
    ],
    exports: [
        MinuteSecondsPipe,
    ],
})
export class PipesModule { }
