import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { Subscription } from 'rxjs'
import { AppConstants } from 'src/app/app.constants'
import { ChatboxHttpService } from '../../chatbox-http.service'
import { ActiveUser } from '../../models/ActiveUser'

@Component({
  selector: 'app-receiver-list-item',
  templateUrl: './receiver-list-item.component.html',
  styleUrls: ['./receiver-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReceiverListItemComponent implements OnInit, OnDestroy {

  constructor(
    private chatboxHttp: ChatboxHttpService,
  ) { }

  userData: any = {}
  $sub: any


  @Input() isSelected = false
  @Input() user: ActiveUser
  @Input() hasUnread = false
  @Input() dumbName = ''
  @Input() p2pMatchId: string = undefined


  @Output() itemClicked: EventEmitter<any> = new EventEmitter()

  isOnlineSubscription$: Subscription
  isActive = '0'

  isBye = false

  subscribeToActive(id) {
    return this.chatboxHttp.subscribeToUserStatus( id ).subscribe(
      (data: string | null) => {

        if ( data === null ) {
          this.isBye = true
          this.isActive = '0'
        }

        if (data) {
          this.isActive = data
        }

      },
    )
  }

  itemClickHandler() {
    const idToHandle = this.p2pMatchId || this.user.id
    this.itemClicked.emit(idToHandle)
  }

  ngOnInit() {

    const id = this.user.id || this.user.userExtraId
    this.isOnlineSubscription$ = this.subscribeToActive(id)

    if ( id ) {
      this.$sub = this.chatboxHttp.getUserByIdCached( id ).subscribe(
        data => {
          if ( data ) {
            this.userData = data
          }
        },
      )
    }
  }

  ngOnDestroy() {
    if (this.$sub) {
      this.$sub.unsubscribe()
    }
    if (this.isOnlineSubscription$) {
      this.isOnlineSubscription$.unsubscribe()
    }
  }

  imageLinkHandler( url: string | undefined): string {
    if (!url) {
      return 'assets/images/no-ava.png'
    } else {
      return url.startsWith('http') ? url : AppConstants.SERVER_API_URL + url
    }

  }

  isActiveToStatus() {

    if (!this.isActive) {
      return ''
    }
    switch ( this.isActive.toString() ) {
        case '1':
        return 'Online'
        case '0':
        return 'Offline'
    }
  }
}
