import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '../../reducers/index'
import { Observable } from 'rxjs'
import { selectNavStages, selectNavRooms } from '../timeline-feature.selectors'
import { toggleNavStageItem, toggleNavRoomItem } from '../timeline-feature.actions'

@Component({
  selector: 'app-tournament-matches-filter',
  templateUrl: './tournament-matches-filter.component.html',
  styleUrls: ['./tournament-matches-filter.component.scss'],
})
export class TournamentMatchesFilterComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
  ) { }


  selectStageActive
  selectRoomActive

  navStagesState: Observable<NavStagesState>
  navRoomsState: Observable<NavRoomsState>

  ngOnInit() {

    this.navStagesState = this.store.select(selectNavStages)
    this.navRoomsState = this.store.select(selectNavRooms)

  }

  onApply() {
    this.selectRoomActive = false
    this.selectStageActive = false
  }

  roomsClickHandler(room: NavRoom) {
    this.store.dispatch(
      toggleNavRoomItem( { value: room.id } ),
    )
  }

  getRoomName(name: string, index: string): string {
    return name.startsWith('[')
      ? name
      : `[${index}] ${name}`
  }

  stagesClickHandler(stage: string): void {
    console.log(stage)
    this.store.dispatch(toggleNavStageItem( { value: stage }))
  }

}

export interface NavRoom {
  disabled: boolean
  checked: boolean
  value: string
  label: string
  stage: string
  id: string
  tourMatches?: any
  stageIndex: string
  // tournamentId: 
}

export interface NavStage {
    label: string
    value: string
    checked: boolean
}

export interface NavStagesState {
  [key: string]: NavStage
}

export interface NavRoomsState {
  [key: string]: NavRoom
}
