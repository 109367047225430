import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { AppConstants } from 'src/app/app.constants'
import { AppState } from 'src/app/reducers'
import { TournamentsService } from 'src/app/services/tournament.service'
import { pushMismatchImage } from '../../timeline/timeline-feature.actions'
import { selectMatches } from '../../timeline/timeline-feature.selectors'
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-result-modal',
  templateUrl: './edit-result-modal.component.html',
  styleUrls: ['./edit-result-modal.component.scss'],
})
export class EditResultModalComponent implements OnInit, OnDestroy {

  constructor(
    private tournamentsService: TournamentsService,
    public dialogRef: MatDialogRef<EditResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    ) {
  }

  inputScoreA = 0
  inputScoreB = 0
  matchId: any
  baseUrlImage: string = AppConstants.BASE_IMAGE_URL
  destroy$: Subject<boolean> = new Subject<boolean>()
  match


  matchesSub$

  // dispatch() {
  //   const hardcodedEvt = {
  //     node: 'A',
  //     userExtraId: 1202,
  //     imageUrl: 'https://ajaxapp.s3.eu-north-1.amazonaws.com/1576018832204-avatar.jpeg',
  //     eventType: 'updateScoreMissMatchPhoto',
  //     matchId: '15110',
  //   }

  //   const { node, imageUrl, matchId } = hardcodedEvt
  //   this.store.dispatch(pushMismatchImage( { node, imageUrl, matchId } ))
  // }

  ngOnDestroy() {
    this.destroy$.next(true)
  }
  ngOnInit() {
    this.matchId = this.data.matchId

    this.store.select(selectMatches)
    .pipe(takeUntil(this.destroy$))
    .subscribe( matches => {
      this.match = matches.filter(match => match.id === this.matchId)[0 ] })

  }

  onClose() {
    this.dialogRef.close()
  }

  onSaveResult() {
    const decideTheWinnerDTO = {
      tournamentId: this.data.tournamentId,
      tourMatchId: this.match.id,
      inputScoreA: this.inputScoreA,
      inputScoreB: this.inputScoreB,
    }
    console.log(decideTheWinnerDTO)
    this.tournamentsService.decideTheWinner(decideTheWinnerDTO).subscribe(
       (rs) => {
         console.log(rs)
         this.dialogRef.close(true)
        },
       err => console.log(err),
      )

  }

  onMinus(value) {
    if (value === 'A' && this.inputScoreA > 0) {
      this.inputScoreA--
    }
    if (value === 'B' && this.inputScoreB > 0) {
      this.inputScoreB--
    }
  }
  onPlus(value) {
    if (value === 'A') {
      this.inputScoreA++
    }
    if (value === 'B') {
      this.inputScoreB++
    }
  }

  genClassFlag(isoCode: string) {
    return isoCode ? 'flag-icon-' + isoCode.toLowerCase() : ''
  }

}
