import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromChatbox from '../reducers/chatbox.reducer'
import { deepDiffInitialState } from '../reducers/chatbox.utils'

export const selectChatboxState = createFeatureSelector<fromChatbox.ChatboxState>(
  fromChatbox.chatboxFeatureKey,
 )

export const selectNormalized = createSelector(
  selectChatboxState,
  ({ supportNormalizedData, moderatorDirectUnreadData,
    unreadData: { MODERATOR_TOURNAMENT_MESSAGE, GLOBAL_CHAT_MESSAGE } }) =>
  ({ supportNormalizedData, moderatorDirectUnreadData,
    moderatorTournamentData: MODERATOR_TOURNAMENT_MESSAGE, moderatorGlobalData: GLOBAL_CHAT_MESSAGE }),
)

export const selectUnreadTotal = createSelector(
  selectChatboxState,
  ( { }) => {},
)


export const selectTournamentsDirect = createSelector(
  selectChatboxState,
  ( { tournamentsDirect } ) => tournamentsDirect,
)

export const getOpen = createSelector(selectChatboxState, ({ open }) => open )
export const getActiveTab = createSelector(selectChatboxState, ({ activeTab }) => activeTab )

export const getTournamentId = createSelector(selectChatboxState, ({ tournamentId }) => tournamentId)

export const selectTmMessages = createSelector(
  selectChatboxState,
  ({ tmMessages, tournamentId }) => tmMessages && tournamentId && tmMessages[tournamentId],
)
export const selectTmMessagesUnread = createSelector(
  selectChatboxState,
  ({ unreadData: { MODERATOR_TOURNAMENT_MESSAGE }, tournamentId }) =>
    MODERATOR_TOURNAMENT_MESSAGE && MODERATOR_TOURNAMENT_MESSAGE[tournamentId] && MODERATOR_TOURNAMENT_MESSAGE[tournamentId].length || 0,
)

export const selectTmUnreadData = createSelector(
  selectChatboxState,
  ({ unreadData: { MODERATOR_TOURNAMENT_MESSAGE }, tournamentId }) =>
    MODERATOR_TOURNAMENT_MESSAGE && MODERATOR_TOURNAMENT_MESSAGE[tournamentId] || [],

)

export const selectModeratorsDirectList = createSelector(
  selectChatboxState,
  ({ moderatorChatList }) => moderatorChatList,
)

export const selectModeratorDirectActiveChat = createSelector(
  selectChatboxState,
  ({ moderatorActiveChat }) => moderatorActiveChat,
)

export const getSender = (participants, senderId) => participants.find( ({ id }) => senderId === id)



export const selectIfActiveChatIsRead = createSelector(
  selectChatboxState,
  ({ moderatorChatList, moderatorActiveChat }) => {
    return moderatorChatList[moderatorActiveChat].currentParticipant.messageRead
  },
)

export const selectActiveModeratorsChatMessages = createSelector(
  selectChatboxState,
  ({ moderatorChatList, moderatorActiveChat } ) => {

    if (!moderatorActiveChat) {
      return []
    }

    const chat = moderatorChatList[moderatorActiveChat]
    const { participants, messages, currentParticipant: { id } } = chat

    const normalized = messages.map( msg => ({
      ...msg,
       sender:  getSender(participants, msg.senderId),
       isMine: msg.senderId === id,
      }) )

    return normalized
  },
)

export const selectHasModeratorsChatUnread = createSelector(
  selectChatboxState,
  ({ moderatorChatList, moderatorActiveChat }) => {

    let hasUnread = false

    Object.values(moderatorChatList).map(
      (chat: any) => {
        if (chat.currentParticipant.messageRead === false ) {
          hasUnread = true
        }
      },
    )

    return hasUnread
  },
)

export const selectActiveModeratorsDirectTournamentChat = createSelector(
  selectChatboxState,
  ({ tournamentsDirect, tournamentId }) => tournamentsDirect[tournamentId] || {},
)

export const selectActiveModeratorsDirectTournamentId = createSelector(
  selectChatboxState,
  ({ tournamentsDirect, tournamentId }) => tournamentsDirect[tournamentId] ? tournamentsDirect[tournamentId].activeId : undefined,
)

export const selectActiveModeratorsDirectTournamentMessages = createSelector(
  selectChatboxState,
  ({ tournamentsDirect, tournamentId }) => {

    if ( tournamentsDirect[tournamentId] ) {

      const chat = tournamentsDirect[tournamentId] || {chatData: {}, activeId: undefined}
      const { chatData = {}, activeId = '' } = chat
      return chatData[activeId] || []
    }
    return []
  },
)

export const selectDirectMessagesDiffById = createSelector(
  selectChatboxState,
  ( state, props) => {
     const { directMessages } = state
     const { tournamentId } = props

     console.log( '[Selector with props]', props )
     return directMessages[tournamentId].diffWithPrevious || deepDiffInitialState
  },
)


export const selectReportsDiff = createSelector(
  selectChatboxState,
  ( { diffWithPrevious }) => diffWithPrevious,
)

export const selectReportsActiveMessages = createSelector(
  selectChatboxState,
  ({ tournamentId, directMsgData }) => {
    if ( tournamentId && directMsgData && directMsgData[tournamentId] ) {

      const tournament = directMsgData[tournamentId]
      const { data, activeId } = tournament
      if (data[activeId]) {
        return data[activeId]
      }
    }
    return {}
  },
)


export const selectReportsByActiveTournament = createSelector(
  selectChatboxState,
  ({ tournamentId, directMsgData }) => directMsgData && tournamentId && directMsgData[tournamentId] ? directMsgData[tournamentId] : {},
)

export const selectReportsActiveId = createSelector(
  selectChatboxState,
  ({ tournamentId, directMsgData }) => {

    if (tournamentId && directMsgData && directMsgData[tournamentId]) {

      const tournament = directMsgData[tournamentId]
      const { activeId } = tournament

      return activeId || undefined
    }
    return undefined
  },

)


export const selectModeratorTournamentUnreadState = createSelector(
  selectChatboxState,
  ({ moderatorDirectUnreadData = {}, tournamentId }) => {
    return moderatorDirectUnreadData[tournamentId]
  },
)


export const selectSupportUnreadState = createSelector(
  selectChatboxState,
  ({ supportNormalizedData = {}, tournamentId }) => {
    return supportNormalizedData[tournamentId]
  },
)
