import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { of } from 'rxjs'
import { concatMap, withLatestFrom } from 'rxjs/operators'
import { AppState } from 'src/app/reducers'
import * as ChatboxActions from '../actions/chatbox.actions'
import * as AccountSelectors from '../auth/account.selectors'
import { ChatboxTabs } from '../chatbox/models/ChatboxTabs.enum'
import * as TournamentDataActions from '../tournament-data/store/tournament-data.actions'
import * as UnreadActions from '../unread/store/unread-feature.actions'
// import { fetchModeratorChatList, fetchModeratorChatListSuccess } from '../actions/chatbox.actions'
import { WebSocketClient } from './socket-client-service'
import * as SocketManagerActions from './socket-manager.actions'
import { onMessage, onModeratorsSubscribed } from './socket-manager.actions'


@Injectable()
export class SocketManagerEffects implements OnInitEffects {

  afterSocketConnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketManagerActions.connectSuccess),
      concatMap(() => of( ChatboxActions.fetchModeratorChatList() ) ),
    ),
  )


  subscribeToNotStarted = createEffect( () => {
    return this.actions$.pipe(
      ofType(ChatboxActions.fetchModeratorChatListSuccess),
      concatMap( ({ data }) => {

        data.map( tournament => this.socketClient.topicSubscribe(`/chat/${tournament.id}`) )
        // data.filter(tournament => tournament.status === 0 ).map( ({ id }) => this.socketClient.reportsSubscribe(id) )

        return of(onModeratorsSubscribed())
        },
      ),
     )
  })

  onInitiateChatSuccess = createEffect( () => {
    return this.actions$.pipe(
      ofType(TournamentDataActions.initiateChatSuccess),
      withLatestFrom(
        this.store.select(AccountSelectors.selectUserId),
        this.store.select(AccountSelectors.selectIsHeadOrSuperModerator),
      ),
      concatMap( ( [ { userId }, id, isHeadOrSuper ] ) => {

        this.socketClient.subscribeToDirectChatWithUser(userId, id, isHeadOrSuper)
        return of(onModeratorsSubscribed())

      }),
    )
  })

  subscribeToLive = createEffect( () => {
    return this.actions$.pipe(
      ofType(TournamentDataActions.fetchTournamentsSuccess),
      withLatestFrom(
        this.store.select(AccountSelectors.selectUserId),
        this.store.select(AccountSelectors.selectIsHeadOrSuperModerator),
      ),
      concatMap( ( [ { data }, userId, isHeadOrSuper ] ) => {

        const notFinishedTournaments = data.filter( tournament => tournament.status !== 2 )

        notFinishedTournaments.map(
          ({ id }) => {
            this.socketClient.subscribeToDirectChatWithUser(id, userId, isHeadOrSuper )
            this.socketClient.subscribeToSharedModeratorsRoom(id)
            this.socketClient.reportsSubscribe(id)
          },
        )

        return of(onModeratorsSubscribed())

        },
      ),
     )
  })

  notifyOnSharedRoomMessage = createEffect( () => {
    return this.actions$.pipe(
      ofType(ChatboxActions.onTmMessage),
      withLatestFrom(
        this.store.select(AccountSelectors.selectUserId),
      ),
      concatMap( ( [{ message, tournamentId }, userId ] ) => {

        const { senderUserExtra, text, chatId/*, tournamentId */ } = message
        if (userId !== senderUserExtra.id) {
          this.socketClient.openSnack(ChatboxTabs.TM, senderUserExtra, text, chatId, tournamentId )
        }
        return of(SocketManagerActions.emptyAction())

      }),
    )
  })



  OnSocketMessage$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(
        onMessage,
        // UnreadActions.loadUnreadInfoSuccess,
        // ChatboxActions.onModeratorDirectTournamentStateChange,
        // ChatboxActions.onModeratorDirect
      ),
      concatMap(() => of(UnreadActions.loadUnreadInfo())),

    )
  })

  ngrxOnInitEffects(): Action {

    console.log(' Socket effects init! ')
    this.socketClient.initClient()
    return { type: '[Chatbox] Fetch Moderator Chat List' }
  }

  constructor(
    private actions$: Actions,
    private socketClient: WebSocketClient,
    private store: Store<AppState>,
    ) {}

}
