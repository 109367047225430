import { CommonModule, DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule, MatInputModule, MatPaginatorIntl, MatTooltipModule } from '@angular/material'
import { MatButtonModule } from '@angular/material/button'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatCardModule } from '@angular/material/card'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { DndModule } from 'ngx-drag-drop'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NgxPaginationModule } from 'ngx-pagination'
import { environment } from '../../environments/environment'
import { ChatboxModule } from '../chatbox/chatbox.module'
import { EditResultModalComponent } from '../tournaments/edit-result-modal/edit-result-modal.component'
import { TournamentMatchPunishCardComponent } from '../tournaments/tournament-match-punish-card/tournament-match-punish-card.component'
import { MatPaginatorIntlModeratorsList } from './components/ajax-paginator.provider'
import { AjaxSnackComponent } from './components/ajax-snack/ajax-snack.component'
import { ConfirmComponent } from './components/confirm/confirm.component'
import { ModeratorFindItemComponent } from './components/moderator-find/moderator-find-item/moderator-find-item.component'
import { ModeratorFindModalComponent } from './components/moderator-find/moderator-find-modal/moderator-find-modal.component'
import { AjaxNotifierComponent } from './components/notifier/ajax-notifier/ajax-notifier.component'
import { NotifyListItemComponent } from './components/notifier/notify-list-item/notify-list-item.component'
import { PushToAllModalComponent } from './components/push-to-all-modal/push-to-all-modal.component'
import { PipesModule } from './pipes/pipes.modules';
import { HoverClassDirective } from './directives/hover-class.directive'




@NgModule({
    imports: [
        CommonModule,
        ChatboxModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        InfiniteScrollModule,
        DndModule,
        PipesModule,
        MatSelectModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatDatepickerModule,
        FormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatButtonModule,
        MatCardModule,
    ],
    exports: [
        ChatboxModule,
        FlexLayoutModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        InfiniteScrollModule,
        DndModule,
        PipesModule,
        MatSelectModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatDatepickerModule,
        FormsModule,
        MatTooltipModule,
        ConfirmComponent,
        MatCheckboxModule,
        EditResultModalComponent,
        TournamentMatchPunishCardComponent,

         AjaxNotifierComponent,
         NotifyListItemComponent,


    ],
    declarations: [
        ConfirmComponent,
        EditResultModalComponent,
        TournamentMatchPunishCardComponent,
        PushToAllModalComponent,
        AjaxSnackComponent,
        ModeratorFindModalComponent,
        ModeratorFindItemComponent,
        AjaxNotifierComponent,
        NotifyListItemComponent,
        HoverClassDirective,
        // ImageLoaderComponent,
    ],
    entryComponents: [
        AjaxSnackComponent,
        ConfirmComponent,
        EditResultModalComponent,
        PushToAllModalComponent,
        TournamentMatchPunishCardComponent,
        ModeratorFindModalComponent,
    ],

    providers: [
        DatePipe,
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlModeratorsList },
    ],

})
export class SharedModule { }
